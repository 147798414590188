<template>
  <v-container>
    <v-row class="text-center">

      <v-col cols="12">
        <v-img
          :src="require('../assets/katsumata_taiya_logo.png')"
          class="my-3"
          contain
          height="200"
        />
      </v-col>



      <img class="exterior_class" src="../assets/front_tireman.webp">

      <v-col
        class="mb-4"
        cols="12"
      >
        <h2 class="heading"><span>specialist</span>タイヤのプロがいる</h2>

        <v-row class="subheading font-weight-regular pl-5 pr-5 text-left">
          <p>当店はブリヂストンの専門店です。乗用車からトラック・ダンプまで、タイヤのことは私たちにお任せください！</p>
        </v-row>
      </v-col>

      <div>
        <h2 class="winter_h2">冬タイヤ</h2>
      </div>
      <CarouselsWinter/>

      <v-col
        class="mb-4"
        cols="12"
      >
        <h2 class="heading"><span>OPEN</span>8:00から営業中</h2>

        <v-row class="subheading font-weight-regular text-left pr-5 pl-5">
          <p>朝8:00から夕方18:00まで営業中！<br>朝早くからタイヤ交換ができます。</p>
        </v-row>
      </v-col>

      <img class="exterior_class" src="../assets/winterbs.webp" loading="lazy">

      <v-col
        class="mb-5"
        cols="12"
      >
        <h2 class="heading"><span>Welcome</span>ご新規さま大歓迎！</h2>

        <v-row class="subheading font-weight-regular text-left pr-5 pl-5">
          <p>初めてのお客様、転勤や引越しで御殿場に来た方など大歓迎です！<br>
          車検前にタイヤを交換したい方、御殿場の冬に必須なスタッドレスタイヤの交換など、タイヤに関してお困りのことがございましたら
          お気軽にご相談ください！</p>
        </v-row>
      </v-col>

      <img class="exterior_class_kk" src="../assets/kk50th.png" loading="lazy">

      <v-col class="mb-4">
        <h2 class="heading"><span>History</span>創業50年目</h2>
        <v-row class="subheading font-weight-regular text-left pr-5 pl-5">
        <p>
          勝間田タイヤは御殿場のみなさまに支えられて創業50年目を迎えました。今後もより良いサービスの向上に努めて参りますので、どうぞよろしくお願いいたします。
        </p>
        </v-row>
      </v-col>

      <img class="exterior_class" src="../assets/newopen2016.webp" loading="lazy">

      <v-col class="mb-4">
        <h2 class="heading"><span>NEW</span>リニューアルオープン</h2>
        <v-row class="subheading font-weight-regular text-left pr-5 pl-5">
        <p>
          お店と作業ピットは2016年に新しく建てました。
          店内が綺麗だと快適に作業をお待ちいただけると思います。<br>
          また敷地も広くなったのでダンプなどの大型車も入りやすいようになりました。

        </p>
        </v-row>
      </v-col>

      <img class="exterior_class" src="../assets/parking_big.webp" loading="lazy">

      <v-col class="mb-4">
        <h2 class="heading"><span>for truck</span>トラック用の入口</h2>
        <p class="subheading pr-3 pl-3 text-left">
          乗用車用の駐車場とは別に大型車の入りやすい広めの入口を作りました✨<br>大型車でもスムーズに出入りできます！
        </p>
      </v-col>

      <img class="exterior_class" src="../assets/forclient.webp" loading="lazy">

      <v-col class="mb-4">
        <h2 class="heading"><span>Cliant</span>法人向けサービス</h2>
        <p class="subheading pr-3 pl-3 text-left">
          勝間田タイヤではトラック・営業車・社用車などの商用車もタイヤ交換が可能です。<br>
          車両のタイヤチェックなどもできますので、お気軽にお問い合わせください。
        </p>
        <v-row class="ml-auto">
          <v-btn to="/client">詳しくは→</v-btn>
        </v-row>
      </v-col>


      <img class="exterior_class2" src="../assets/discount.webp" loading="lazy">

      <v-col class="mb-4">
        <h2 class="heading"><span>Discount</span>他店の見積書ご提示で</h2>
        <p class="subheading pr-3 pl-3 text-left">
          カーディーラーさんや他タイヤ販売店のブリヂストンタイヤのお見積書を持ってきていただければ、それより安くお値下げできるように頑張ります。<br><br>
          ※ネット通販店や、系列店のタイヤ館御殿場以外のお店
        </p>
      </v-col>

      <img class="exterior_class2" src="../assets/recruit.webp" loading="lazy">

      <v-col class="mb-4">
        <h2 class="heading"><span>Recruit</span>正社員募集中</h2>
        <p class="subheading pr-3 pl-3 text-left">
          タイヤ交換、トラックタイヤ交換経験者大歓迎です！<br>
          11月、12月、4月のタイヤ履き替え時期限定のアルバイトも募集しています！<br>
          時給1200円<br>
          電話番号 : 0550-89-1219<br>
          メール : <a href="mailto:kohki612@gmail.com">kohki612@gmail.com</a><br>
          詳しくは上記連絡先までお問い合わせお待ちしています！
        </p>
      </v-col>


    </v-row>
  </v-container>
</template>

<script>
import CarouselsWinter from '@/components/CarouselsWinter.vue'
  export default {
    name: 'HelloWorld',
    components: {
      CarouselsWinter
      },
    data: () => ({
      ecosystem: [
        {
          text: 'vuetify-loader',
          href: 'https://github.com/vuetifyjs/vuetify-loader',
        },
        {
          text: 'github',
          href: 'https://github.com/vuetifyjs/vuetify',
        },
        {
          text: 'awesome-vuetify',
          href: 'https://github.com/vuetifyjs/awesome-vuetify',
        },
      ],
      importantLinks: [
        {
          text: 'Documentation',
          href: 'https://vuetifyjs.com',
        },
        {
          text: 'Chat',
          href: 'https://community.vuetifyjs.com',
        },
        {
          text: 'Made with Vuetify',
          href: 'https://madewithvuejs.com/vuetify',
        },
        {
          text: 'Twitter',
          href: 'https://twitter.com/vuetifyjs',
        },
        {
          text: 'Articles',
          href: 'https://medium.com/vuetify',
        },
      ],
      whatsNext: [
        {
          text: 'Explore components',
          href: 'https://vuetifyjs.com/components/api-explorer',
        },
        {
          text: 'Select a layout',
          href: 'https://vuetifyjs.com/getting-started/pre-made-layouts',
        },
        {
          text: 'Frequently Asked Questions',
          href: 'https://vuetifyjs.com/getting-started/frequently-asked-questions',
        },
      ],
    }),
  }
</script>

<style scoped>

@media screen and ( max-width:767px )
{
  .exterior_class{
    width: 100%;
    height: auto;
    padding-bottom: 1.5rem;
  }
  .exterior_class2{
    width: 100%;
    height: auto;
    padding-top: 5rem;
    padding-bottom: 1.5rem;
  }
  .exterior_class_kk{
  width: 80%;
  margin: 0 auto;
  padding-bottom: 1.5rem;
  } 
}
@media screen and ( min-width:768px )
{
  .exterior_class{
    width: 50%;
    height: auto;
    margin:0 auto;
    padding-bottom: 1.5rem;
  }
  .exterior_class2{
    width: 100%;
    height: auto;
    padding-top: 5rem;
    padding-bottom: 1.5rem;
  }
  .exterior_class_kk{
  width: 50%;
  margin: 0 auto;
  padding-bottom: 1.5rem;
}
}
p{
  letter-spacing: .12em;
  line-height: 1.6rem;
  padding-bottom: 4rem;
  font-weight: 500;
}
h2{
  padding-top: 2rem;
  padding-bottom: 2rem;
}
h1{
  padding-bottom: 2rem;
}
.winter_h2{
  padding-left: 1.5rem;
  padding-bottom: 2rem;
  padding-top: 2rem;
}
.heading {
   font-size: 30px;
   letter-spacing: 0.12rem;
}

.heading span { /*英字テキストの指定*/
   display: flex;
   align-items: center; /*英字テキストと線の上下中央配置*/
   margin-bottom: 10px;
   color: #92C7CF;
   font-size: 18px;
   font-style: italic;
   font-family: 'Montserrat', sans-serif;
   text-transform: uppercase; /*英字の大文字表記*/
}

.heading span::before { /*黄色の線を擬似要素で表現*/
   content: '';
   display: inline-block;
   margin-right: 20px;
   width: 40px;
   height: 1px;
   background-color: #92C7CF;
}
</style>
